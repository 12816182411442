import styled from "styled-components"
import { device, fonts } from "../styles/stylesConfig"
import { WavyBackground } from "./WavyBackground"
import { useState } from "react"
import Slider from 'react-slick';
import { useSelector } from "react-redux";
import { getHexadecimalByPrimaryColorState, getTicketsList, getWaveColorsByPrimaryColorState } from "../utils/Utilities";
import { TicketProps } from "../interfaces/TicketInterfaces";
import MemorizedTicketCard from "./TicketCard";

const Container = styled.div`
    width: 100%;
    min-height: 80vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    margin-top: 10vh;

    &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -50%;
        top: -150px;
        background-color: #111;
        box-shadow: 50px -75px 50px 0px #111;
    }

    @media ${device.sm} {
        margin-top: 0vh;
    }
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: -14vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 50px 0px 100px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media ${device.sm} {
        margin: 20px;
        margin: 0px 0px 20px 0px;
    }

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 42px;
        text-transform: uppercase;
        color: #BAF18D;
        letter-spacing: 5px;
        text-align: center;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.xs} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        margin-bottom: 50px;
    }
`

const List = styled.div`

    // SI HAY DOS ENTRADAS

     /* width: 800px;

    @media ${device.lg} {
        width: 600px;
    }

    @media ${device.md} {
        width: 500px;
    }

    @media ${device.sm} {
        width: 380px;
    }

    @media ${device.xs} {
        width: 280px;
    }  */

    // SI HAY DOS ENTRADAS

    //////////////////////////

    // SI HAY TRES+ ENTRADAS

    width: 1200px;

    @media ${device.lg} {
        width: 800px;
    }

    @media ${device.md} {
        width: fit-content;
    }

`

const SliderContainer = styled(Slider)`

    @media ${device.md} {
        display: none;
    }

   
`

const CardsContainer = styled.div`
    width: 100%;
    display: none;

    @media ${device.md} {
        display: unset;

    }
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;
    outline: none;
    overflow: hidden;
    position: relative;

    &:hover {

        & > div {
            /* transform: rotateY(180deg); */
            transform: scale(1.05);
            & > div {
                opacity: 1;
                backdrop-filter: blur(10px);
                /* transition-delay: 0.1s; */
            }

        }

    }

`

export const NextDates = () => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const [items, setItems] = useState<TicketProps[]>([
        {
            id: 2,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/200624-miami-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/200624-miami-flyer-mobile.webp`,
            primaryColor: '#02C9D8',
            secondaryColor: '#fff',
            place: `ELLA MIAMI CLUB`,
            capitalPlace: `Florida`,
            location: '55 NE 24th St | Miami',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-location.webp`,
            date: 'Jueves 20 de Junio 2024 | 23:00 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.toliv.com/eventos/usa/miami/ella-miami-club-copa-america-11936?coupon_event=WASABI',
            logEvent: 'click_entrada_20/06/24_miami',
            showUntil: 20240620
        },
    ]);

    return (

        <Container >

            <Target id="tickets" />

            <ContentContainer>

                <TitleContainer>

                    <h1
                        style={{
                            color: getHexadecimalByPrimaryColorState(primaryColor),
                            fontFamily: fonts.secondary
                        }}
                        className="utils__fade-in-fast-top"
                    >Próximas fechas</h1>


                </TitleContainer>

                <List>

                    <SliderContainer
                        slidesToShow={3}
                        vertical={false}
                        waitForAnimate
                        infinite={false}
                        arrows={false}
                        swipe={false}
                        dots={false}
                    // pauseOnHover
                    // autoplay
                    // autoplaySpeed={4000}
                    >

                        {getTicketsList(items)[0].flyerDesktop ? (

                            getTicketsList(items).map((item: TicketProps, index: any) => {
                                return (

                                    <CardContainer key={index.toString()}>

                                        <MemorizedTicketCard item={item} />

                                    </CardContainer>

                                )
                            })

                        ) : <></>

                        }

                    </SliderContainer>

                    <CardsContainer>

                        {getTicketsList(items)[0].flyerDesktop ? (

                            getTicketsList(items).map((item: TicketProps, index: any) => {

                                return (

                                    <CardContainer key={index.toString()}>

                                        <MemorizedTicketCard item={item} />

                                    </CardContainer>

                                )
                            })

                        ) : <></>

                        }

                    </CardsContainer>



                </List>

            </ContentContainer>

            <WavyBackground colors={getWaveColorsByPrimaryColorState(primaryColor)} />


        </Container >

    )
}

export default NextDates;