import styled from "styled-components"
import { device, fonts } from "../styles/stylesConfig"
import { Suspense, lazy, memo } from "react"
import { TicketProps } from "../interfaces/TicketInterfaces";
import { useLogEvent } from "../hooks/useLogEvent";

const MemorizedStars = lazy(() => import("./MemorizedStars"));

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    /* transition: transform 0.8s;
    transform-style: preserve-3d; */

    & > div {
        transition: all 0.5s ease;
        opacity: 0;
        /* transition-delay: 0s;
        transform: rotateY(180deg); */
        /* box-shadow: 0px 2px 20px 0px rgba(255, 255, 255, 0.2); */
    }

    @media ${device.md} { /* 280 x 120 */
        padding: 20px;
    }

   
`

const Image = styled.img`
    width: auto;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    @media ${device.lg} {
        width: 250px;
        height: 400px;
    }

    @media ${device.md} {
        height: 400px;
        width: 400px;
    }

    @media ${device.sm} { 
        height: 250px;
        width: 250px;
    }

    @media ${device.xs} {
        height: 200px;
        width: 200px;
    }
`

const TextDiv = styled.div`
    width: 282px !important;
    height: 501px !important;
    top: 29px;
    left: 59px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media ${device.lg} {
        width: 230px !important;
        height: 401px !important;
        left: 19px;
    }

    @media ${device.md} {
        width: 400px !important;
        height: 400px !important;
        left: 20px;
        top: 20px;
    }

    @media ${device.sm} {
        width: 250px !important;
        height: 250px !important;
    }

    @media ${device.xs} { 
        width: 200px !important;
        height: 200px !important;
    }
`

const Place = styled.p`
    font-size: 2rem !important;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-family: ${fonts.secondary};

    @media ${device.lg} {
        font-size: 1.5rem !important;
        margin-bottom: 10px;
    }
    @media ${device.md} {
        font-size: 1.5rem !important;
        margin-bottom: 10px;
    }

    @media ${device.sm} {
        margin-bottom: 0px;
        font-size: 1.2rem !important;
    }

    @media ${device.xs} { 
        font-size: 1rem !important;
    }

    & > span {
        font-family: ${fonts.secondary};
        position: relative;
        font-size: 1.5rem !important;
        font-weight: 300;

        @media ${device.lg} {
            font-size: 1.2rem !important;
        }

        @media ${device.sm} {
            font-size: 1rem !important;
        }

        @media ${device.xs} { 
            font-size: 0.8rem !important;
        }
    }

`

const DataDiv = styled.div`
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Icon = styled.img`
    width: 45px;
    height: 45px;
    margin-right: 20px;
    margin-left: 5px;
    
    @media ${device.lg} {
        width: 35px;
        height: 35px;
        margin-left: 5px;
    }

    @media ${device.md} {
        width: 35px;
        height: 35px;
        margin-left: 5px;
    }

    @media ${device.sm} {
        width: 25px;
        height: 25px;
        margin-right: 15px;
    }

    @media ${device.xs} { 
        width: 20px;
        height: 20px;
    }
`

const Data = styled.p`
    font-size: 18px;
    color: #fff;
    font-family: ${fonts.secondary};

    @media ${device.lg} {
        font-size: 14px;
    }

    @media ${device.md} {
        font-size: 14px;
    }

    @media ${device.sm} {
        font-size: 12px;
    }

    @media ${device.xs} { 
        font-size: 10px;
    }
`

const BuyButton = styled.button`
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    margin-top: 30px;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    z-index: 1;
    font-size: 14px;
    font-family: ${fonts.secondary};
    
    &:hover {
        transform: scale(1.02);
    }


    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    @media ${device.lg} {
        width: 90%;
        padding: 15px 10px;
        margin-top: 15px;
        font-size: 12px !important;

        img {
            display: none;
        }
    }

    @media ${device.md} {
        padding: 15px 10px;
        margin-top: 5px;
        font-size: 12px !important;
    }

    @media ${device.sm} {
        padding: 10px 5px;
        margin-top: 0px;
        font-size: 11px !important;
    }

    @media ${device.xs} { 
        width: 90%;
        font-size: 9px !important;
        padding: 5px 5px;
        font-weight: 500;
    }
`

interface Props {
    item: TicketProps;
}

const TicketCard = ({ item }: Props) => {

    const { handleLogEvent } = useLogEvent();

    return (

        <Card>

            <Image
                src={window.innerWidth <= 900 ? item.flyerMobile : item.flyerDesktop}
                alt="flyer"
            />

            <TextDiv
                className="h-full w-full absolute m-auto overflow-hidden bg-[#00000092]"
                aria-hidden="true"
            >

                <Place
                    style={{
                        color: item.primaryColor
                    }}
                    className="w-full text-center font-bold bg-clip-text text-transparent"
                >
                    {item.capitalPlace}<br /><span>{item.place}</span>
                </Place>

                <DataDiv>

                    <Icon src={item.dateIcon} alt="calendario" />

                    <Data>{item.date}</Data>

                </DataDiv>

                <DataDiv>

                    <Icon src={item.locationIcon} alt="Ubicacion" />

                    <Data>{item.location}</Data>

                </DataDiv>

                <BuyButton
                    tabIndex={-1}
                    style={{ backgroundColor: item.primaryColor, color: item.secondaryColor }}
                    onClick={(e) => {

                        e.preventDefault();

                        window.open(item.link, '_blank');

                        handleLogEvent(item.logEvent);

                    }}
                >

                    <Icon src={item.buyIcon} alt="Ticket" />

                    Comprar mi entrada

                </BuyButton>

                <Suspense fallback={<></>}>

                    <MemorizedStars />

                </Suspense>

            </TextDiv>

        </Card>


    )
}

const MemorizedTicketCard = memo(TicketCard);

export default MemorizedTicketCard;