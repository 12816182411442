import { addDays } from "date-fns";

export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}

export const getDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());

    fullDate = `${year}${month}${day}`;

    return fullDate;

}

const formatTwoNumbers = (month) => {

    let m = month;

    if (m < 10) {

        m = `0${m}`;

    }

    return m;

}

export const fillWithCeros = (inputString, length) => {
    // Verificar la longitud actual del string
    if (inputString.length < length) {
        // Calcular la cantidad de ceros que se deben agregar
        const cerosLeft = length - inputString.length;

        // Completar con ceros a la izquierda
        const string = '0'.repeat(cerosLeft) + inputString;

        return string;
    } else {
        // Si ya tiene 12 caracteres o más, devolver el string original
        return inputString;
    }
}

export const formatPrice = (price) => {

    let p = price;
    const internationarFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 })

    p = internationarFormat.format(p);

    return p;

} 

export const getHexadecimalByPrimaryColorState = (primaryColorState) => {

    let p = '#BAF18D';

    if (primaryColorState === '2') {
        p = '#FF93E3';
    } 
    if  (primaryColorState === '3') {
        p = '#FFB270';
    } 
    if  (primaryColorState === '4') {
        p = '#A4E6FF';
    } 
    if  (primaryColorState === '5') {
        p = '#FFFFFF';
    } 

    return p;

} 

export const getWaveColorsByPrimaryColorState = (primaryColorState) => {

    let p = [
        "#a1ff43",
        "#00f97d",
        "#90fc84",
        "#2fff00",
        "#66ffb5",
    ];

    if (primaryColorState === '2') { // rosa
        p = [
            "#ea43bd",
            "#eebce0",
            "#ca77b4",
            "#874a77",
            "#FF93E3",
        ];
    } 
    if  (primaryColorState === '3') { // naranja
            p = [
            "#ec8429",
            "#f8c497",
            "#915a2a",
            "#d29158",
            "#FFB270",
        ];

    } 
    if  (primaryColorState === '4') { // celeste
        p = [
            "#52bce2",
            "#267d9d",
            "#7bb6cb",
            "#A4E6FF",
            "#A4E6FF",
        ];
    } 
    if  (primaryColorState === '5') { // blanco
            p = [
            "#fbfbfb",
            "#ffffff",
            "#bdbdbd",
            "#343434",
            "#d4ede1",
        ];
    } 

    return p;

} 

export const getRandomColorState = (primaryColorState) => {

    let result = Math.floor(Math.random() * 5) + 1;

    if (result.toString() === primaryColorState) {

        if (result.toString() === '5') {
            result = 1
        } else {
            result++;
        }
        
    }

    return result.toString();
} 

export const getRandomArrayValue = (array) => {

    const length = array.length

    let result = Math.floor(Math.random() * length);

    return result;
} 

export const getTicketsList = (list) => {

    let newList = list.filter((value) => { return value.showUntil >= parseInt(getDate()) });

    return newList
}

export const getAddedDate = (daysAdded) => {

    const d = new Date();

    const date = addDays(d, daysAdded)

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}
